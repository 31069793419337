import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import Home from './pages/Home';
import ApproveCustomer from './pages/ApproveCustomer';
import VideoApproveCustomer from './pages/VideoApproveCustomer';
import Wishlist from './pages/Wishlist';
import BuildForShopify from './pages/BuildForShopify';
import ApiCheck from './pages/ApiCheck';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/approvecustomer" element={<Navigate to="/approvecustomer/quicksetup" />} />
        <Route path="/approvecustomer/quicksetup" element={<ApproveCustomer />} />
        <Route path="/approvecustomer/videos" element={<VideoApproveCustomer />} />
        <Route path="/buildforshopify" element={<BuildForShopify />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import React from 'react';
import { useState, useCallback, useEffect } from 'react';

import '../form.css';

const ApiCheck = () => {

	const [emailAlready, setEmailAlready] = useState('Email Already Taken! Try a different Email or Login');
	const [loginLabel, setLoginLabel] = useState("Sign In");
	const [loginPosition, setLoginPosition] = useState("bottom");
	const [loginPrefix, setLoginPrefix] = useState("Already have an account?");
	const [type, setType] = useState("wholesale");
	const [submitLabel, setSubmitLabel] = useState("Submit");
	const [formFields, setFormFields] = useState([]);
	const [formLoading, setFormLoading] = useState(true);
	const [successMessage, setSuccessMessage] = useState("Your application is successfully submitted, we will check your details and send you activation email soon. It can take few days");

  function rearrangeArrayByPosition(array) {
		array.sort((a, b) => a.position - b.position);
		return array;
	}
/*
  const fetchData = async () => {

    const bodyData = {
      shop:'thuma-staging.myshopify.com',
      formid:'17261796379761',
      contact_email:'finaltest@gmail.com',
      First_Name:'Approve',
      Last_Name:'Customer',
      Phone_Number:'9876543210',
      Company_Name:'SureCust',
      Website_Or_Social:'https://www.thuma.co/pages/hospitality-trade',
      Address_1:'Vijay Nagar',
      Address_2:'Vijay Nagar Indore',
      City:'Indore',
      State:'Texas',
      Zip_Code:'85234',
      Country:'85234',
      Area_of_Focus:'Trade / Residential Projects, Hospitality / Commercial Projects, Brand Partnership',
      Verifying_Document:'Document Link',
      Message:'Message Test',
      How_did_you_hear_about_us:'Word of mouth'
    }

    try {
      const response = await fetch('https://approve.surecust.com/api/headless-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': '6d5ff41469bbe6795109dc9d4263bb6e42ce870457ac9f6fcce0be0a2176d2f3',
        },
        body:JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error('Invalid API key or Invalid Shop or Invalid Form ID');
      }

      const result = await response.json();
      console.log(result);
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };
  */

  useEffect(() => {
  //  fetchData();
  }, []);

  console.log(formFields);

  return (
    <section>
      <div className='onst-registration-form SureCust-App'>
        <div className='onst-registration-form-container onst-big-form SureCust-App'>
          <h2 className="title">Wholesale Application</h2>
          <p className="subtitle">Submit the application by filling in this form.</p>
          <div className="onst-custom-form-box SureCust-App">
            {formLoading?<>
              <div className="onst-custom-form-box-loader"><span className="onst-loading-icon"></span></div>
            </>:<>
              <div className="onst-registration-form-container-box SureCust-App">
                <form method="post" className="onst-custom-form onst-show-labels SureCust-App" action="/account" autoComplete="on" acceptCharset="UTF-8">
                  <input type="hidden" name="form_type" value="create_customer"/>
                  <div className="onst-register-form-fields SureCust-App">
                    {formFields.map((data,index) => {
                        console.log(data);
                        console.log(index);
                        var fieldClass="onst-field onst-size-"+data.size;
                        var fieldId = "wRegisterForm-"+data.type+"-"+index;
                        if( data.type == "country_state" ){
                          var fieldIdcc = "wRegisterForm-ccountry-"+index;
                          var fieldIdss = "wRegisterForm-sstate-"+index;
                          return (
                            <React.Fragment key={fieldIdcc + fieldIdss}>
                              <div key={fieldIdcc} className="onst-field onst-size-half">
                                {data.clabel!==''?<><label htmlFor={fieldIdcc}>{data.clabel}</label></>:""}
                                <select className="onstcountryselector" name={data.cname} id={fieldIdcc}></select>
                                <span className="onst-warning">{data.crequiremsg!==''?data.crequiremsg:"This field is required."}</span>
                              </div>
                              <div key={fieldIdss} className="onst-field onst-size-half">
                                {data.slabel!==''?<><label htmlFor={fieldIdss}>{data.slabel}</label></>:""}
                                <select className="onststateselector" name={data.sname} id={fieldIdss}></select>
                                <span className="onst-warning">{data.srequiremsg!==''?data.srequiremsg:"This field is required."}</span>
                              </div>
                            </React.Fragment>
                          );
                        }else if( data.type == "customer_address"){
                          var fieldIdc = "wRegisterForm-country-"+index;
                          var fieldIds = "wRegisterForm-state-"+index;
                          var fieldIda1 = "wRegisterForm-address1-"+index;
                          var fieldIda2 = "wRegisterForm-address2-"+index;
                          var fieldIdcty = "wRegisterForm-city-"+index;
                          var fieldIdz = "wRegisterForm-zip-"+index;
                          return(
                            <React.Fragment key={fieldIda1 + fieldIda2 + fieldIdcty + fieldIdz + fieldIdc + fieldIds}>
                              <div key={fieldIda1} className="onst-field onst-size-full">
                                {data.address1label!==''?<><label htmlFor={fieldIda1}>{data.address1label}</label></>:""}
                                <input type="text" name={data.address1name} id={fieldIda1} autoComplete="on" placeholder={data.address1placeholder}/>
                                <span className="onst-warning">{data.address1requiremsg!==''?data.address1requiremsg:"This field is required."}</span>
                              </div>
                              <div key={fieldIda2} className="onst-field onst-size-full">
                                {data.address2label!==''?<><label htmlFor={fieldIda2}>{data.address2label}</label></>:""}
                                <input type="text" name={data.address2name} id={fieldIda2} autoComplete="on" placeholder={data.address2placeholder}/>
                                <span className="onst-warning">{data.address2requiremsg!==''?data.address2requiremsg:"This field is required."}</span>
                              </div>
                              <div key={fieldIdcty} className="onst-field onst-size-half">
                                {data.ctylabel!==''?<><label htmlFor={fieldIdcty}>{data.ctylabel}</label></>:""}
                                <input type="text" name={data.ctyname} id={fieldIdcty} autoComplete="on" placeholder={data.ctyplaceholder}/>
                                <span className="onst-warning">{data.ctyrequiremsg!==''?data.ctyrequiremsg:"This field is required."}</span>
                              </div>
                              <div key={fieldIdz} className="onst-field onst-size-half">
                                {data.ziplabel!==''?<><label htmlFor={fieldIdz}>{data.ziplabel}</label></>:""}
                                <input type="text" name={data.zipname} id={fieldIdz} autoComplete="on" placeholder={data.zipplaceholder}/>
                                <span className="onst-warning">{data.ziprequiremsg!==''?data.ziprequiremsg:"This field is required."}</span>
                              </div>
                              <div key={fieldIdc} className="onst-field onst-size-half">
                                {data.clabel!==''?<><label htmlFor={fieldIdc}>{data.clabel}</label></>:""}
                                <select className="onstcountryselector" name={data.cname} id={fieldIdc}></select>
                                <span className="onst-warning">{data.crequiremsg!==''?data.crequiremsg:"This field is required."}</span>
                              </div>
                              <div key={fieldIds} className="onst-field onst-size-half">
                                {data.slabel!==''?<><label htmlFor={fieldIds}>{data.slabel}</label></>:""}
                                <select className="onststateselector" name={data.sname} id={fieldIds}></select>
                                <span className="onst-warning">{data.srequiremsg!==''?data.srequiremsg:"This field is required."}</span>
                              </div>
                            </React.Fragment>
                          );
                        }else{
                          return (
                            <div key={index} className={fieldClass}>
                              {(data.type == "customer_subscribe"||data.type == "title"||data.type=="country_state")?<></>:<>
                                {data.label!==''?<><label htmlFor={fieldId}>{data.label}</label></>:""}
                              </>}
                              {(data.type == "first_name" || data.type == "last_name" || data.type == "text" || data.type == "password" || data.type == "number" || data.type == "tel" || data.type == "customer_phone" || data.type == "customer_password")?<>
                                {( data.type == "password" || data.type == "customer_password" )?<>
                                  <div className="onst-password-field"><input type="password" name={data.name} id={fieldId} placeholder={data.placeholder}/><span className="password-view-js">&#128065;</span></div>
                                </>:""}
                                {data.type == "number"?<>
                                  <input type="number" min={data.min} max={data.max} name={data.name} id={fieldId} autoComplete="on" placeholder={data.placeholder}/>
                                </>:""}
                                {( data.type == "tel" || data.type == "customer_phone" )?<>
                                  <input type="tel" name={data.name} id={fieldId} autoComplete="on" placeholder={data.placeholder}/>
                                </>:""}
                                {( data.type == "first_name" || data.type == "last_name" || data.type == "text" )?<>
                                  <input type="text" name={data.name} id={fieldId} autoComplete="on" placeholder={data.placeholder}/>
                                </>:""}
                              </>:""}
                              {( data.type == "customer_email" || data.type == "email")?<>
                                <input type="email" name={data.name} id={fieldId} autoComplete="on" placeholder={data.placeholder}/>
                              </>:""}
                              {data.type == "textarea"?<>
                                <textarea rows="5" name={data.name} id={fieldId} placeholder={data.placeholder}></textarea>
                              </>:""}
                              {data.type == "select"?<>
                                <select name={data.name} id={fieldId}>
                                  {data.options.length>0?<>
                                    {data.options.map((option,oindex) => {
                                      return(
                                        <option key={oindex} value={option.value}>{option.label}</option>
                                      );
                                    })}
                                  </>:""}
                                </select>
                              </>:""}
                              {(data.type == "radio"||data.type == "checkbox")?<>
                                <div className="onst-field-roptions">
                                  {data.roptions.length>0?<>
                                    {data.roptions.map((roption,roindex) => {
                                      var fieldInnerId = fieldId+'-inner-'+roindex;
                                      return(
                                        <div key={roindex} className="onst-field-roptions-item">
                                          <label htmlFor={fieldInnerId}>
                                            {roption.checked=='yes'?<>
                                              <input type={data.type == "checkbox"?"checkbox":"radio"} name={data.name} id={fieldInnerId} value={roption.value} checked />
                                            </>:<>
                                              <input type={data.type == "checkbox"?"checkbox":"radio"} name={data.name} id={fieldInnerId} value={roption.value} />
                                            </>}
                                            <span className="onst-field-roption-value">{roption.label}</span>
                                          </label>
                                          {roption.description!==''?<>
                                            <p>{roption.description}</p>
                                          </>:<></>}
                                        </div>
                                      );
                                  })}
                                  </>:""}
                                </div>
                              </>:""}
                              {data.type == "customer_subscribe"?<>
                                  <div className="onst-field-roptions">
                                    <div className="onst-field-roptions-item">
                                        <label htmlFor={fieldId}>
                                          {data.checked?<>
                                            <input type="checkbox" name={data.name} id={fieldId} value="true" checked />
                                          </>:<>
                                            <input type="checkbox" name={data.name} id={fieldId} value="true" />
                                          </>}
                                          <span className="onst-field-roption-value">{data.label}</span>
                                        </label>
                                        {data.description!==''?<>
                                          <p>{data.description}</p>
                                        </>:<></>}
                                    </div>
                                  </div>
                              </>:""}
                              {data.type == "upload"?<>
                                  <div className="onst-field-upload-box">
                                    <div className="onst-file-input">
                                      <label htmlFor={fieldId}>
                                        <span className="onst-upload-icon"></span>
                                        <div>{data.placeholder!==""?data.placeholder:"Choose a file:"}<span className="onst-selected-file-name"></span></div>
                                      </label>
                                      <input type="file" id={fieldId}/><div className="upload-file-loader"><span className="upload-loader-progress"></span></div>
                                    </div>
                                    <input type="hidden" name={data.name}/>
                                  </div>
                                  {data.description!==""?<p>{data.description}</p>:""}
                              </>:""}
                              {data.type == "title"?<>
                                <h4 className="onst-field-title">{data.name}</h4>
                              </>:<>
                                <span className="onst-warning">{data.requiremsg!==''?data.requiremsg:"This field is required."}</span>
                              </>}
                            </div>
                          );
                        }
                    })}
                  </div>
                </form>
              </div>
            </>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApiCheck;